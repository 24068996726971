'use strict';

/*!
 * Name: Isle of Anlgesey County Council JS
 * Author: Zengenti Ltd
 * Author URI: http://zengenti.com
 * Description: Isle of Anglesey County Council
 * Version: 0.0.1
 */

var app = function ($) {
    'use strict';

    // create cookie

    function createCookie(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toGMTString();
        } else {
            expires = '';
        }
        document.cookie = name + '=' + value + expires + '; path=/';
    }

    // read cookie
    function readCookie(name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    // private alias to settings
    var s;

    return {
        settings: function settings() {
            this.$window = $(window);
            this.$menuToggle = $('.js-menu-toggle');
            this.$searchToggle = $('.js-search-toggle');
            this.$accordion = $('.accordion');
            this.$timer = $('#timer');
            this.$slideshow = $('.hero__carousel');

            this.circleLoop = 'M14,1c7.2,0,13,5.8,13,13s-5.8,13-13,13S1,21.2,1,14S6.8,1,14,1';
        },

        init: function init() {
            s = new this.settings();

            this.cookie();
            this.menu();
            this.tabs();
            this.mobileMenu();
            this.alertBanner();
            this.feedback();
            this.cookieBar();
            this.accordian();
            this.search();
            this.allCarousels();
            this.searchFilters();
            this.splashTemplate();
            this.stepByStepGuide();
            this.logoHomeLink();
            this.bindUIActions(); // UI Actions (click, focus etc)
            this.bindWindowActions(); // Window actions (scrolling, resize etc)

            if (s.$timer.length) {
                this.svgTimer();
            }
        },

        // set cookie
        cookie: function cookie() {

            var cookieLanguage = Cookies.get('language');

            if (document.querySelector('.splash-template') !== null) {
                // Set cookie
                $('.default-language').click(function (e) {
                    var active = $(this);
                    var setCookieLanguage = $(active).data('language');
                    var setCookieUrl = $(active).attr('href');

                    Cookies.set('language', setCookieLanguage, { path: '/' });
                });
            }
        },

        // master menu
        menu: function menu() {

            if (window.location.href.indexOf("folderIds") > -1) {
                var queryString = [],
                    hash;
                var q = document.URL.split('?')[1];
                if (q != undefined) {
                    q = q.split('&');
                    for (var i = 0; i < q.length; i++) {
                        hash = q[i].split('=');
                        queryString.push(hash[1]);
                        queryString[hash[0]] = hash[1];
                    }
                }

                var $folderIdSplit = queryString['folderIds'].split(',');
                var $folderLength = $folderIdSplit.length;

                for (var i = 0; i < $folderLength; i++) {

                    if (i > 0) {

                        var activeFolder = $('.m-menu').find('[data-id=' + $folderIdSplit[i] + "]");
                        loadNextLevelBreadcrumb($folderIdSplit[i]);
                    }
                }

                var level = $folderLength;
                // level = parseInt(level[$]) + 1;
                var menuClass = 'm-menu m-menu--l' + level;

                if (level <= 4) {
                    $('.m-menu').attr('class', menuClass);
                }
            }

            // $( document ).ajaxComplete(function( event, xhr, settings ) {

            // });


            $(document).on('click', '.m-menu__item', function (e) {
                if (!$(this).hasClass('active') && !$(this).hasClass('page-link')) {
                    app.killEvent(e);
                    loadNextLevel($(this));

                    $(this).addClass('active');
                    $(this).siblings().removeClass('active');
                    var tab = $(this).closest('.m-menu__tab').attr('class').split(' ');
                    var menuLevel = tab[1];
                    var level = menuLevel.split('--l');
                    level = parseInt(level[1]) + 1;
                    var menuClass = 'm-menu m-menu--l' + level;
                    if (level <= 4) {
                        $('.m-menu').attr('class', menuClass);
                    }
                }
            });

            $(document).on('click', '.m-menu__back', function (e) {
                app.killEvent(e);

                var tab = $(this).closest('.m-menu__tab').attr('class').split(' ');
                var menuLevel = tab[1];

                var level = menuLevel.split('--l');
                level = parseInt(level[1]) - 1;

                var menuClass = 'm-menu m-menu--l' + level;
                $('.m-menu').attr('class', menuClass);

                var $menuTab = $(this).closest('div.m-menu__tab');

                var $previousTab = $menuTab.prev();
                if ($previousTab) {
                    $previousTab.find('li.m-menu__item.active').removeClass('active');
                }

                var menuClass = 'm-menu m-menu--l' + level;
                $('.m-menu').attr('class', menuClass);
                updateQueryString();
            });

            function updateQueryString() {
                var ids = $('li.m-menu__item.active').map(function () {
                    return $(this).data('id');
                }).get();
                if (ids && history.pushState) {
                    var queryString = '?folderIds=' + ids.join(',');
                    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + queryString;
                    window.history.pushState({ path: newurl }, '', newurl);
                }
            }
            function loadNextLevelBreadcrumb(itemId) {

                var getDest = '/api/custom/navigation/GetLevelMarkupByFolderId/' + itemId;
                $.get(getDest, function (response) {
                    updateQueryString();
                    $('div.m-menu').append(response);
                    $('[data-id=' + itemId + ']').addClass('active');
                    $('div.m-menu__tab').last().find('li.m-menu__item > a').first().focus();
                    console.log('Loaded level from: ' + getDest);
                });
            }

            function loadNextLevel(item) {
                var $menuTab = item.closest('div.m-menu__tab');
                var $siblingsAfter = $menuTab.nextAll();
                var getDest = '/api/custom/navigation/GetLevelMarkupByFolderId/' + item.data('id');
                $.get(getDest, function (response) {
                    $siblingsAfter.remove();
                    updateQueryString();
                    $('div.m-menu').append(response);
                    $('div.m-menu__tab').last().find('li.m-menu__item > a').first().focus();
                    console.log('Loaded level from: ' + getDest);
                });
            }
        },

        tabs: function tabs() {
            $('ul.tabs').each(function () {
                var $active,
                    $content,
                    $links = $(this).find('a');

                // If the location.hash matches one of the links, use that as the active tab.
                // If no match is found, use the first link as the initial active tab.
                var $locationQuerySplit = location.hash.split('?');

                $active = $($links.filter('[href="' + $locationQuerySplit[0] + '"]')[0] || $links[0]);
                $active.addClass('active');

                $content = $($active[0].hash);

                $links.not($active).each(function () {
                    $(this.hash).removeClass('active').slideUp(400);
                });

                $(this).on('click', 'a', function (e) {

                    // Make the old tab inactive.
                    $active.removeClass('active');
                    $content.removeClass('active').slideUp(400);

                    // Update the variables with the new link and content
                    $active = $(this);
                    $content = $(this.hash);

                    // Make the tab active.
                    $active.addClass('active');
                    $content.addClass('active').delay(700).slideDown(400);

                    e.preventDefault();
                });
            });
        },

        mobileMenu: function mobileMenu() {
            $('.js-menu-toggle').click(function () {
                $(this).toggleClass('close-menu-icon');
                $('.main-nav').toggleClass('open-menu');
            });

            if (!$('.main-nav a').hasClass('active')) {
                $('.mobile-active').css('height', '0');
            } else {
                $('.main-nav a.active').clone().appendTo('.mobile-active');
            }
        },

        alertBanner: function alertBanner() {

            var $alertBannerClose = $('.alert-close');
            var $alertBar = $('.alert-banner');
            var versionId = $alertBannerClose.data('version-id');
            var currentLanguage = window.location.pathname.substr(1, 2);
            var cookieName = currentLanguage == "en" ? "alertBannerEn" : "alertBannerCy";
            var cookie = readCookie(cookieName);

            if (cookie == null || cookie != versionId) {
                $alertBar.css('display', 'block');
                $alertBar.attr('aria-hidden', 'false');
            }

            $alertBannerClose.click(function () {
                createCookie(cookieName, versionId, 90);
                $alertBar.css('display', 'none');
                $alertBar.attr('aria-hidden', 'true');
            });
        },

        feedback: function feedback() {
            var feedbackForm = $('.feedback__form');
            var feedbackSubmit = $('.feedback__form .sys_buttons');
            var pageUrl = window.location.href;

            if (feedbackForm) {

                var feedbackFormTextArea = $('.feedback__form--textarea');
                var feedbackFormUrl = $('.feedback__form .sys_cms-form-item.sys_hiddenfield input');
                $(feedbackFormTextArea).hide();
                $(feedbackSubmit).hide();

                $(feedbackFormUrl).val(pageUrl);

                $(".feedback__form--radio :radio").change(function () {

                    var feedbackFormRadio = $('.feedback__form--radio :radio:checked');
                    var radioValue = $(feedbackFormRadio).val();

                    if (radioValue === "Yes" || radioValue === "Ydy") {
                        $(feedbackFormTextArea).hide();
                        $(feedbackSubmit).show();
                    } else {
                        $(feedbackFormTextArea).show();
                        $(feedbackSubmit).show();
                    }
                });
            }
        },

        cookieBar: function cookieBar() {

            var $cookieBarClose = $('.cookie-close');
            var $cookieBar = $('.cookie-bar');
            var cookieName = 'cookieAcknowledge';
            var cookie = readCookie(cookieName);

            if (cookie == null || cookie != 'acknowledged') {
                $cookieBar.css('display', 'block');
                $cookieBar.attr('aria-hidden', 'false');
            }

            $cookieBarClose.click(function () {
                createCookie(cookieName, 'acknowledged', 90);
                $cookieBar.css('display', 'none');
                $cookieBar.attr('aria-hidden', 'true');
            });
        },

        accordian: function accordian() {
            $('.accordian-section__title').on('click', function (e) {
                e.preventDefault();

                if ($(this).closest('.accordian-section').hasClass('open-accordian')) {
                    $('.accordian-section').each(function () {
                        $(this).removeClass('open-accordian');
                        $('.accordian-section__title').attr('aria-expanded', 'false');
                    });
                } else {
                    $('.accordian-section').each(function () {
                        $(this).removeClass('open-accordian');
                        $('.accordian-section__title').attr('aria-expanded', 'false');
                    });
                    $(this).closest('.accordian-section').addClass('open-accordian');
                    $(this).attr('aria-expanded', 'true');
                }
            });
        },

        search: function search() {
            $('.js-search-toggle').on('click', function (e) {
                e.preventDefault();
                $('.search-overlay').addClass('open');
                $('.search-box input[type="search"]').focus();
            });
            $('.close-search').on('click', function (e) {
                e.preventDefault();
                $('.search-overlay').removeClass('open');
            });

            $(window).on('resize load', function () {
                if ($(window).width() > 993) {
                    $('.mobile-search').hide();
                }
            });
            $('.search-icon__mobile').on('click', function (e) {
                e.preventDefault();
                $('.mobile-search').toggleClass('open');
            });

            $(document).keyup(function (e) {
                if (e.keyCode == 27) {
                    if ($('.search-overlay').hasClass('open')) {
                        e.preventDefault();
                        $('.search-overlay').removeClass('open');
                    }
                }
            });

            $('.search-box button').click(function (e) {
                e.preventDefault();
                var inputField = $('#search').val();
                console.log(inputField);
            });
        },

        searchFilters: function searchFilters() {
            $('.search-filter__heading').on('click', function (e) {
                e.preventDefault();
                $(this).parent().toggleClass('open closed');
            });

            $('.search-filter__mobile a').on('click', function (e) {
                e.preventDefault();
                $(this).toggleClass('open');
                $('.search-filter').toggleClass('search-filter--active');
            });
        },

        allCarousels: function allCarousels() {

            if ($("#news-room").length == 1) {
                var checkExist = setInterval(function () {
                    if ($('.twitter__cycle').length) {
                        $('.twitter__cycle').cycle();
                        clearInterval(checkExist);
                    }
                }, 100); // check every 100ms
            }

            $('.hero__carousel').cycle();

            if ($('.carousel')) {
                $('.carousel').cycle();
            }

            if ($('.cycle-slideshow')) {
                $('.cycle-slideshow').cycle({
                    captionTemplate: "{" + "{slideNum}" + "} of {" + "{slideCount}" + "}"
                });
            }

            var slideshows = $('.cycle-slideshow').on('cycle-next cycle-prev', function (e, opts) {
                // advance the other slideshow
                slideshows.not(this).cycle('goto', opts.currSlide);
            });

            $('#cycle-2 .cycle-slide').click(function () {
                var index = $('#cycle-2').data('cycle.API').getSlideIndex(this);
                slideshows.cycle('goto', index);
            });
        },

        splashTemplate: function splashTemplate() {

            if (document.querySelector('.splash-template') !== null) {
                var html = document.documentElement;
                html.className += ' full-height';
            }
        },

        svgTimer: function svgTimer() {

            var loopLength = Snap.path.getTotalLength(s.circleLoop);

            var sn = Snap('#timer');

            var circleOutline = sn.path({
                path: Snap.path.getSubpath(s.circleLoop, 0, 0),
                stroke: '#F6BF35',
                fillOpacity: 0,
                strokeWidth: 0
            });

            function snapAnimate() {
                Snap.animate(0, loopLength, function (step) {
                    //step function
                    circleOutline.attr({
                        path: Snap.path.getSubpath(s.circleLoop, 0, step),
                        strokeWidth: 3
                    });
                }, // end of step function
                8000, //duration
                mina.easeInOut, //easing
                function () {
                    //callback
                    setTimeout(function () {
                        circleOutline.attr({
                            path: Snap.path.getSubpath(s.circleLoop, 0, 0),
                            strokeWidth: 0
                        });
                    }, 1000); //setTimeout
                } //callback
                );
            }

            snapAnimate();

            s.$slideshow.on('cycle-initialized cycle-after', function () {
                if (!s.$slideshow.is('.cycle-paused')) {
                    snapAnimate();
                }
            });

            s.$slideshow.on('cycle-paused', function () {
                s.$timer.hide();
            });

            s.$slideshow.on('cycle-resumed', function () {
                s.$timer.show();
                snapAnimate();
            });
        },

        pauseCarousel: function pauseCarousel(carousel) {
            var controls = $('#controls');

            if (!$(carousel).is('.cycle-paused')) {
                $(carousel).cycle('pause');

                $(controls).find('.icon-slider-pause').hide();
                $(controls).find('.icon-slider-play ').show();
            } else {
                $(carousel).cycle('resume');

                $(controls).find('.icon-slider-play ').hide();
                $(controls).find('.icon-slider-pause').show();
            }
        },

        stepByStepGuide: function stepByStepGuide() {

            if ($('.guide')[0]) {
                var showStep = function showStep(n) {

                    var stepNumber = document.getElementsByClassName("step");
                    stepNumber[n].style.display = "block";

                    if (n == 0) {
                        document.getElementsByClassName("previous__step")[0].style.display = "none";
                    } else {
                        document.getElementsByClassName("previous__step")[0].style.display = "inline-block";
                    }
                    if (n == stepNumber.length - 1) {
                        document.getElementsByClassName("next__step")[0].style.display = "none";
                    } else {
                        document.getElementsByClassName("next__step")[0].style.display = "inline-block";
                    }
                };

                var previousStep = function previousStep() {
                    event.preventDefault();
                    var stepNumber = document.getElementsByClassName("step");
                    stepNumber[currentStep].style.display = "none";
                    currentStep = currentStep - 1;
                    showStep(currentStep);
                };

                var nextStep = function nextStep() {
                    event.preventDefault();
                    var stepNumber = document.getElementsByClassName("step");
                    stepNumber[currentStep].style.display = "none";
                    currentStep = currentStep + 1;
                    showStep(currentStep);
                };

                var printGuide = function printGuide() {
                    var stepNumber = document.getElementsByClassName("step");
                    for (var i = 0; i < stepNumber.length; i++) {
                        showStep(i);
                    }
                    window.print();
                };

                var currentStep = 0;

                showStep(currentStep);

                document.getElementsByClassName("next__step")[0].addEventListener("click", nextStep);
                document.getElementsByClassName("previous__step")[0].addEventListener("click", previousStep);
                document.getElementById("printGuide").addEventListener("click", printGuide);
            }
        },

        logoHomeLink: function logoHomeLink() {
            var logoContainer = $('.header__content .logo img');
            var currentLanguage = window.location.pathname.substr(1, 2);
            var homeLink = currentLanguage == "en" ? "en/Residents/Residents.aspx" : "cy/Trigolion/Trigolion.aspx";

            if (logoContainer) {

                $(logoContainer).wrap('<a href="/' + homeLink + '"></a>');
            }
        },

        bindUIActions: function bindUIActions() {

            $('#pause').on('click', function (e) {
                app.pauseCarousel('.hero__carousel');
                e.preventDefault();
            });
        },

        bindWindowActions: function bindWindowActions() {},

        // ** START CONTENSIS EVENT KILL FUNCTIONS **
        killEvent: function killEvent(eventObject) {
            if (eventObject && eventObject.stopPropagation) {
                eventObject.stopPropagation(eventObject);
            }
            if (window.event && window.event.cancelBubble) {
                window.event.cancelBubble = true;
            }
            if (eventObject && eventObject.preventDefault) {
                eventObject.preventDefault(eventObject);
            }
            if (window.event) {
                window.event.returnValue = false;
            }
        }

    };
}(jQuery);

jQuery(document).ready(function () {
    app.init();
});